import axios from 'axios';

import cacheStorage from './cacheStorage';

import appConfig from './config';

const { API_BASE_URL } = appConfig;
/**
 * This class is responsible for maintaining API calls to Guesty services.
 */
export default class {
  /**
   * Creates instance of the Api module.
   * @param {Boolean} noApi - disable api call, use default settings
   * @param {Object} config - Configuration for the Api.
   * @param {Object} config.siteUrl - Custom domain name of the website.
   * It's used as website identity when we retrieve access token for the website.
   * @returns {Promise<Api>} Promissed instance of Api.
   */
  constructor({
    noApi,
    siteUrl,
    noCache,
  }) {
    this.requestsPool = {};
    this.noApi = !!noApi;
    this.siteUrl = siteUrl;
    this.noCache = !!noCache;
    this.widgetSettings = {
      color: '#000',
      maxGuests: 16,
      cities: [],
      customDomain: '',
      guestyDomain: '',
      isLocaleEnabled: false,
    };

    // return Promise.resolve(this);
  }

  getWidgetSettings() {
    const { noApi, siteUrl } = this;
    if (noApi) return this;
    return this.request({
      refreshCache: this.noCache,
      method: 'GET',
      url: '/api/booking-engine-widget/widget',
      params: {
        siteUrl,
      },
    })
        .then((widgetSettings) => {
          return this.widgetSettings = {
            ...this.widgetSettings,
            ...widgetSettings,
          };
        })
  }

  /**
   * Returns HTTP headers collection based on custom headers provided via arguments and default.
   * @param {Object} headers - Custom HTTP headers collection.
   * @returns {Object} HTTP headers collection.
   */
  getHeaders(headers = {}) {
    const defaultHeaders = {
      // This header is not allowed by Guesty API CORS Policy:
      // 'cache-control': 'public, max-age=30411000'
    };

    return { ...defaultHeaders, ...headers };
  }

  /**
   * Makes actual HTTP call to the endpoint.
   * @param {Object} config - HTTP request parameters.
   * @param {Object} config.headers - Key-Value collection of HTTP headers.
   * @param {string} config.url - Relative URL to the API endpoint to be called.
   * @param {string} config.baseUrl - to overwrite API_BASE_URL in some cases.
   * @returns {Promise<Object>} Result of the HTTP call.
   */
  httpCall({
    headers, url, baseUrl, ...config
  }) {
    const requestURL = `${baseUrl || API_BASE_URL}${url}`;
    return axios({
      ...config,
      headers: this.getHeaders(headers),
      url: requestURL,
    })
      .then(({ data }) => data)
      .catch((err) => {
        console.error(err);
        return Promise.reject(err);
      });
  }

  /**
   * Wraps http call with different caching mechanisms.
   * @param {Object} config - HTTP request parameters.
   * @param {boolean} config.refreshCache - If true, cache will not be used for this request.
   * @param {string} config.url - Relative URL to the API endpoint to be called.
   * @param {string} config.method - HTTP method.
   * @returns {Promise<Object>} Result of the HTTP call.
   */
  request({
    refreshCache = false, url, method = 'GET', baseUrl, ...config
  }) {
    if (!url || !url.length) {
      throw new Error('url is required');
    }

    /**
     * Wraps httpCall method, pass argumants.
     * @returns {Promise<Object>} Result of the HTTP call.
     */
    const httpCall = () =>
      this.httpCall({
        url, method, baseUrl, ...config,
      });

    /**
     * If HTTP VERB is not GET (read data) - don't use requests pool
     * or responses cache - process request
     */
    if (method !== 'GET') { // TODO: write test for method !== 'GET'
      return httpCall();
    }

    /**
     * This key doesn't include query or body parameters.
     * Cache is shared among all guesty widgets within the same domain.
     */
    const key = `search_widget_${method}_${url}`;

    if (refreshCache !== true) { // TODO: write test for refreshCache === true
      const value = cacheStorage.getItem(key);

      // the required data is cached in browser - no need to request it from API
      if (value) { return Promise.resolve(value); }
    }

    let promise = this.requestsPool[key];

    // request is already pending - don't make two similar HTTP calls
    if (promise) { return promise; }

    promise = httpCall()
      .then((data) => {
        delete this.requestsPool[key];
        if (typeof data === 'object' && data !== null) {
          cacheStorage.setItem(key, data);
        }
        return data;
      })
      .catch((err) => {
        delete this.requestsPool[key];
        return Promise.reject(err);
      });

    this.requestsPool[key] = promise;

    return promise;
  }
}
