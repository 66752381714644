export const translations = {
    'en': {
        searchBtn: 'Search',
        datepicker: {
            checkIn: 'Check in',
            checkOut: 'Check out',
            locale: {
                one: 'day',
                other: 'days',
            },
        },
        guests: {
            label: 'Guests',
            placeholder: 'Select an option...'
        },
        cities: {
            placeholder: 'Destination',
        },
        errors: {
            paramsShouldBeAnObjectError: 'params has to be an object.',
            browserEnvironmentError: 'This plugin should be used only in browser environment!',
            browserVersionError: 'Your browser version is incompatible with this plugin!',
            paramsRequiredError: 'siteUrl is required',
            citiesRequiredError: 'cities is required',
            maxGuestsRequiredError: 'maxGuests is required',
        }
    },
    'de': {
        searchBtn: 'Suchen',
        datepicker: {
            checkIn: 'Einchecken',
            checkOut: 'Auschecken',
            locale: {
                one: 'day',
                other: 'days',
            },
        },
        guests: {
            label: 'Gäste',
            placeholder: 'Wählen Sie eine Option...'
        },
        cities: {
            placeholder: 'Reiseziel',
        },
        errors: {
            paramsShouldBeAnObjectError: 'params muss ein Objekt sein.',
            browserEnvironmentError: 'Dieses Plug-in sollte nur in einer Browserumgebung verwendet werden!',
            browserVersionError: 'Ihre Browserversion ist mit diesem Plug-in nicht kompatibel!',
            paramsRequiredError: 'siteUrl ist erforderlich',
            citiesRequiredError: 'Städte sind erforderlich',
            maxGuestsRequiredError: 'maxGuests ist erforderlich',
        }
    },
    'gr': {
        searchBtn: 'Αναζήτηση',
        datepicker: {
            checkIn: 'Check in',
            checkOut: 'Check out',
            locale: {
                one: 'day',
                other: 'days',
            },
        },
        guests: {
            label: 'Επισκέπτες',
            placeholder: 'Επιλέξτε μια επιλογή...'
        },
        cities: {
            placeholder: 'Προορισμός',
        },
        errors: {
            paramsShouldBeAnObjectError: 'Το params πρέπει να είναι αντικείμενο.',
            browserEnvironmentError: 'Αυτή η προσθήκη πρέπει να χρησιμοποιείται μόνο στο περιβάλλον του προγράμματος περιήγησης!',
            browserVersionError: 'Η έκδοση του προγράμματος περιήγησης που χρησιμοποιείτε δεν είναι συμβατή με αυτή την προσθήκη!',
            paramsRequiredError: 'Το siteUrl απαιτείται',
            citiesRequiredError: 'Το στοιχείο cities απαιτείται',
            maxGuestsRequiredError: 'Το στοιχείο maxGuests απαιτείται',
        }
    },
    'it': {
        searchBtn: 'Cerca',
        datepicker: {
            checkIn: 'Check in',
            checkOut: 'Check out',
            locale: {
                one: 'day',
                other: 'days',
            },
        },
        guests: {
            label: 'Ospiti',
            placeholder: 'Selezionare un\'opzione...',
        },
        cities: {
            placeholder: 'Destinazione',
        },
        errors: {
            paramsShouldBeAnObjectError: 'params deve essere un oggetto.',
            browserEnvironmentError: 'Questo plugin deve essere utilizzato solo in ambiente browser!',
            browserVersionError: 'La versione del tuo browser non è compatibile con questo plugin!',
            paramsRequiredError: 'siteUrl richiesto',
            citiesRequiredError: 'città richieste',
            maxGuestsRequiredError: 'maxGuests richiesto',
        }
    },
    'jp': {
        searchBtn: '検索',
        datepicker: {
            checkIn: 'チェックイン',
            checkOut: 'チェックアウト',
            locale: {
                one: 'day',
                other: 'days',
            },
        },
        guests: {
            label: 'ゲスト',
            placeholder: 'オプションを選択...',
        },
        cities: {
            placeholder: '目的地',
        },
        errors: {
            paramsShouldBeAnObjectError: 'paramsはオブジェクトである必要があります。',
            browserEnvironmentError: 'このプラグインは、ブラウザ環境でのみ使用する必要があります！',
            browserVersionError: 'お使いのブラウザのバージョンは、このプラグインと互換性がありません！',
            paramsRequiredError: 'siteUrlは必須です',
            citiesRequiredError: '都市は必須です',
            maxGuestsRequiredError: 'maxGuestsは必須です',
        }
    },
    'kr': {
        searchBtn: '검색',
        datepicker: {
            checkIn: '체크인',
            checkOut: '체크아웃',
            locale: {
                one: 'day',
                other: 'days',
            },
        },
        guests: {
            label: '게스트',
            placeholder: '옵션 선택...',
        },
        cities: {
            placeholder: '대상',
        },
        errors: {
            paramsShouldBeAnObjectError: '매개변수는 객체여야 합니다.',
            browserEnvironmentError: '이 플러그인은 브라우저 환경에서만 사용 가능합니다.',
            browserVersionError: '브라우저 버전이 플러그인과 호환되지 않습니다!',
            paramsRequiredError: 'siteUrl은 필수 입력란입니다',
            citiesRequiredError: '도시는 필수 입력란입니다',
            maxGuestsRequiredError: 'maxGuests는 필수 입력란입니다',
        }
    },
    'pl': {
        searchBtn: 'Wyszukaj',
        datepicker: {
            checkIn: 'Zameldowanie',
            checkOut: 'Wymeldowanie',
            locale: {
                one: 'day',
                other: 'days',
            },
        },
        guests: {
            label: 'Goście',
            placeholder: 'Wybierz opcję...'
        },
        cities: {
            placeholder: 'Miejsce docelowe',
        },
        errors: {
            paramsShouldBeAnObjectError: 'params musi być obiektem.',
            browserEnvironmentError: 'Ta wtyczka powinna być używana tylko w środowisku przeglądarki!',
            browserVersionError: 'Twoja wersja przeglądarki jest niezgodna z tą wtyczką!',
            paramsRequiredError: 'siteUrl jest wymagany',
            citiesRequiredError: 'miasta są wymagane',
            maxGuestsRequiredError: 'maxGuests są wymagane',
        }
    },
    'pt': {
        searchBtn: 'Pesquisar',
        datepicker: {
            checkIn: 'Check in',
            checkOut: 'Check out',
            locale: {
                one: 'day',
                other: 'days',
            },
        },
        guests: {
            label: 'Hóspedes',
            placeholder: 'Selecionar uma opção...'
        },
        cities: {
            placeholder: 'Destino',
        },
        errors: {
            paramsShouldBeAnObjectError: 'params tem de ser um objeto.',
            browserEnvironmentError: 'Este plugin apenas deve ser utilizado num ambiente de navegador!',
            browserVersionError: 'A versão do seu navegador não é compatível com este plugin!',
            paramsRequiredError: 'o campo siteUrl é obrigatório',
            citiesRequiredError: 'o campo cities é obrigatório',
            maxGuestsRequiredError: 'o campo maxGuests é obrigatório',
        }
    },
    'cn': {
        searchBtn: '搜索',
        datepicker: {
            checkIn: '入住',
            checkOut: '退房',
            locale: {
                one: 'day',
                other: 'days',
            },
        },
        guests: {
            label: '宾客',
            placeholder: '选择一个选项...'
        },
        cities: {
            placeholder: '目的地',
        },
        errors: {
            paramsShouldBeAnObjectError: 'params 必须为对象。',
            browserEnvironmentError: '此插件仅应在浏览器环境下使用！',
            browserVersionError: '您的浏览器版本与此插件不兼容！',
            paramsRequiredError: 'siteUrl 为必填项',
            citiesRequiredError: '城市为必填项',
            maxGuestsRequiredError: 'maxGuests 为必填项',
        }
    },
    'ro': {
        searchBtn: 'Căutare',
        datepicker: {
            checkIn: 'Check in',
            checkOut: 'Check out',
            locale: {
                one: 'day',
                other: 'days',
            },
        },
        guests: {
            label: 'Oaspeți',
            placeholder: 'Selectați o opțiune...'
        },
        cities: {
            placeholder: 'Destinație',
        },
        errors: {
            paramsShouldBeAnObjectError: 'params trebuie să fie un obiect.',
            browserEnvironmentError: 'Acest plugin ar trebui utilizat doar în mediul de browser!',
            browserVersionError: 'Versiunea browserului nu este compatibilă cu acest plugin!',
            paramsRequiredError: 'siteUrl este obligatoriu',
            citiesRequiredError: 'cities este obligatoriu',
            maxGuestsRequiredError: 'maxGuests este obligatoriu',
        }
    },
    'id': {
        searchBtn: 'Cari',
        datepicker: {
            checkIn: 'Check in',
            checkOut: 'Check out',
            locale: {
                one: 'day',
                other: 'days',
            },
        },
        guests: {
            label: 'Tamu',
            placeholder: 'Pilih opsi...'
        },
        cities: {
            placeholder: 'Destinasi',
        },
        errors: {
            paramsShouldBeAnObjectError: 'Param harus berupa objek',
            browserEnvironmentError: 'Plugin ini hanya boleh digunakan di lingkungan peramban!',
            browserVersionError: 'Versi peramban Anda tidak kompatibel dengan plugin ini!',
            paramsRequiredError: 'siteUrl diperlukan',
            citiesRequiredError: 'kota diperlukan',
            maxGuestsRequiredError: 'maxGuests diperlukan',
        }
    },
    'es': {
        searchBtn: 'Buscar',
        datepicker: {
            checkIn: 'Check in',
            checkOut: 'Check out',
            locale: {
                one: 'day',
                other: 'days',
            },
        },
        guests: {
            label: 'Huéspedes',
            placeholder: 'Seleccione una opción...'
        },
        cities: {
            placeholder: 'Destino',
        },
        errors: {
            paramsShouldBeAnObjectError: 'params debe ser un objeto..',
            browserEnvironmentError: '¡Este plugin solo debe utilizarse en el entorno del navegador!',
            browserVersionError: '¡La versión de su navegador es incompatible con este plugin!',
            paramsRequiredError: 'el campo siteUrl es obligatorio',
            citiesRequiredError: 'el campo ciudades es obligatorio',
            maxGuestsRequiredError: 'el campo  maxGuests es obligatorio',
        }
    },
    'fr': {
        searchBtn: 'Rechercher',
        datepicker: {
            checkIn: 'Arrivée',
            checkOut: 'Départ',
            locale: {
                one: 'jour',
                other: 'jours',
            },
        },
        guests: {
            label: 'Clients',
            placeholder: 'Sélectionnez une option...'
        },
        cities: {
            placeholder: 'Destination',
        },
        errors: {
            paramsShouldBeAnObjectError: 'params doit être un objet.',
            browserEnvironmentError: 'Ce plugin doit être utilisé uniquement dans l\'environnement du navigateur !',
            browserVersionError: 'La version de votre navigateur est incompatible avec ce plugin !',
            paramsRequiredError: 'siteUrl est requis',
            citiesRequiredError: 'villes est requis',
            maxGuestsRequiredError: 'maxGuests est requis',
        }
    },
    'nl': {
      searchBtn: 'Zoek op',
      datepicker: {
        checkIn: 'Inchecken',
        checkOut: 'Bekijk',
        locale: {
          one: 'day',
          other: 'days',
        },
      },
      guests: {
        label: 'Gasten',
        placeholder: 'Selecteer een optie...'
      },
      cities: {
        placeholder: 'Bestemming',
      },
      errors: {
        paramsShouldBeAnObjectError: 'params moet een object zijn.',
        browserEnvironmentError: 'Deze plugin mag alleen worden gebruikt in een browseromgeving!',
        browserVersionError: 'Je browserversie is niet compatibel met deze plugin!',
        paramsRequiredError: 'je moet slechts één van de params opgeven (siteUrl of siteId)',
        citiesRequiredError: 'steden is vereist',
        maxGuestsRequiredError: 'maxGuests is vereist',
      }
    },
};
